import { AnyAction } from 'redux';
import uniqBy from 'lodash-es/uniqBy';

import * as types from 'src/store/types';

interface IPlayItem {
  id?: number;
  uuid: string;
  name?: string;
  description?: string;
  detail?: string;
  coverImageUrl?: string;
  youtubeUrl?: string;
  tags?: string[];
}

export interface IState {
  openPlaylist: boolean;
  play: boolean;
  currentPlayUID: string;
  playlist: IPlayItem[];
  muted: boolean;
}

const initialState = {
  openPlaylist: false,
  play: false,
  currentPlayUID: '',
  playlist: [],
  muted: false,
} as IState;

const reducer = (state = initialState, action: AnyAction): typeof initialState => {
  const { type, payload } = action;
  switch (type) {
    case types.GLOBAL_PLAYER__INIT_STATE: {
      return initialState;
    }
    case types.GLOBAL_PLAYER__SET_PLAY: {
      return {
        ...state,
        play: payload.play,
      };
    }
    case types.GLOBAL_PLAYER__SET_CURRENT_PLAY_UID: {
      return {
        ...state,
        currentPlayUID: payload.uuid,
      };
    }
    case types.GLOBAL_PLAYER__ADD_TO_LIST: {
      return {
        ...state,
        playlist: uniqBy([...state.playlist, payload], 'uuid'),
      };
    }
    case types.GLOBAL_PLAYER__REMOVE_FROM_LIST: {
      const updatedPlaylist = state.playlist.filter(item => item.uuid !== payload.uuid);
      if (updatedPlaylist.length === 0) {
        return initialState;
      }
      return {
        ...state,
        currentPlayUID: payload.uuid === state.currentPlayUID ? updatedPlaylist[0].uuid : state.currentPlayUID,
        playlist: updatedPlaylist,
      };
    }
    case types.GLOBAL_PLAYER__SET_PLAYLIST: {
      return {
        ...state,
        playlist: [...payload.playlist],
      };
    }
    case types.GLOBAL_PLAYER__SET_MUTED: {
      return {
        ...state,
        muted: payload.muted,
      };
    }
    case types.GLOBAL_PLAYER__SET_OPEN_PLAYLIST: {
      return {
        ...state,
        openPlaylist: payload.openPlaylist,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
