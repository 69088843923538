import * as React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import debounce from 'lodash-es/debounce';

import LinearProgress from '@mui/material/LinearProgress';

import * as selectors from 'src/store/selectors';
import { withAuthControl } from 'src/hocs/withAuthControl';
import { routePathConfig } from 'src/routes';
import { useGATracking } from 'src/hooks/useGA';
import { useLog } from 'src/hooks/useLog';
// import { useFingerprint } from 'src/hooks/useFingerprint';

import Layout from './Layout';

const Login = React.lazy(() => import('src/pages/Auth/Login'));
const Register = React.lazy(() => import('src/pages/Auth/Register'));
const ForgotPassword = React.lazy(() => import('src/pages/Auth/ForgotPassword'));
const ResetPassword = React.lazy(() => import('src/pages/Auth/ResetPassword'));
const Workspace = React.lazy(() => import('src/pages/Auth/Workspace'));

const Main = (): JSX.Element => {
  const { log, pageTitle } = useLog();
  const location = useLocation();
  const { clientIP, profile } = useSelector(selectors.selectUser);
  // const { logFingerprint } = useFingerprint();
  useGATracking();

  React.useEffect(() => {
    const debonceTime = 1500;
    const debouncedLog = debounce(log, debonceTime);
    // const debounceLogFingerprint = debounce(logFingerprint, debonceTime);
    if (clientIP && profile?.email) {
      debouncedLog({ triggerType: 'page_view' });
      // debounceLogFingerprint();
    }
  }, [location.pathname, clientIP, profile?.email, pageTitle]);

  return (
    <React.Suspense fallback={<LinearProgress />}>
      <Routes>
        <Route path={routePathConfig.login} element={<Login />} />
        <Route path={routePathConfig.register} element={<Register />} />
        <Route path={routePathConfig.forgotPassword} element={<ForgotPassword />} />
        <Route path={routePathConfig.resetPassword} element={<ResetPassword />} />
        <Route path={routePathConfig.workspace} element={<Workspace />} />
        <Route path={`${routePathConfig.home}*`} element={<Layout />} />
      </Routes>
    </React.Suspense>
  );
};

export default withAuthControl(Main);
