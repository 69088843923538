import React, { useCallback } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import snakeCase from 'lodash-es/snakeCase';

import { Drawer, List, Divider, Collapse } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';

import { routePathConfig } from 'src/routes';
import * as selectors from 'src/store/selectors';
import * as actions from 'src/store/actions';
import { useAccessControl } from 'src/hooks/useAccessControl';
import { getUserName } from 'src/utils/name';
import { useLog } from 'src/hooks/useLog';

import { Item, ProfileItem } from './Item';
import { GUIDE_MENU, MANAGE_MENU, SUPER_MANAGE_MENU } from './menuLists';
import LoadingSideMenu from './LoadingSideMenu';

const ListContainer = styled.div`
  width: 250px;
  font-size: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const TRANSLATION_PATH = 'pages';

interface ISideMenu {
  isOpen: boolean;
  handleOnClose: () => void;
}

const SideMenu = ({ isOpen, handleOnClose }: ISideMenu): JSX.Element => {
  const { t } = useTranslation();
  const { log } = useLog();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const { hasAccessRight } = useAccessControl();
  const { profile = {} } = useSelector(selectors.selectUser);
  const isRoot = profile?.isRoot;
  const isLoading = !profile?.email;

  const handleClickItem = useCallback((routePath: string) => {
    if (routePath.indexOf('login') > -1) {
      dispatch(actions.userLogoutCall());
    }
    navigate(routePath);
    handleOnClose();
  }, []);

  return (
    <Drawer open={isOpen} onClose={handleOnClose}>
      <ListContainer>
        {isLoading && <LoadingSideMenu />}
        {!isLoading && (
          <>
            <List>
              <ProfileItem
                name={getUserName(profile)}
                email={profile.email}
                gender={profile.gender}
                avatarUrl={profile.avatarUrl}
                routePath={routePathConfig.profile}
                handleClickItem={handleClickItem}
              />
              <Divider />
              {GUIDE_MENU.filter(menuItem => hasAccessRight({ functionName: menuItem.key })).map(menuItem => {
                const isActive = location.pathname === `/${menuItem.key}`;
                return (
                  <Item
                    key={menuItem.key}
                    isActive={isActive}
                    title={t(`${TRANSLATION_PATH}:${snakeCase(menuItem.key)}`)}
                    Icon={menuItem.icon}
                    routePath={menuItem.routePath}
                    handleClickItem={(routePath: string) => {
                      log({
                        triggerType: 'click_side_menu',
                        description: menuItem.key,
                      });
                      handleClickItem(routePath);
                    }}
                  />
                );
              })}
            </List>
            <List>
              {isRoot && (
                <>
                  <ListItemButton onClick={() => setOpen(prev => !prev)}>
                    <ListItemIcon>
                      <AdminPanelSettingsOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary='網站管理員' />
                    {open ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={open} timeout='auto' unmountOnExit>
                    <List sx={{ background: '#f5f5f7' }}>
                      {SUPER_MANAGE_MENU.filter(menuItem => hasAccessRight({ functionName: menuItem.key })).map(menuItem => {
                        const isActive = location.pathname === `/${menuItem.key}`;
                        return (
                          <Item
                            key={menuItem.key}
                            isActive={isActive}
                            title={t(`${TRANSLATION_PATH}:${snakeCase(menuItem.key)}`)}
                            Icon={menuItem.icon}
                            routePath={menuItem.routePath}
                            handleClickItem={handleClickItem}
                          />
                        );
                      })}
                    </List>
                  </Collapse>
                  <Divider />
                </>
              )}
              {MANAGE_MENU.filter(menuItem => hasAccessRight({ functionName: menuItem.key })).map(menuItem => {
                const isActive = location.pathname === `/${menuItem.key}`;
                return (
                  <Item
                    key={menuItem.key}
                    isActive={isActive}
                    title={t(`${TRANSLATION_PATH}:${snakeCase(menuItem.key)}`)}
                    Icon={menuItem.icon}
                    routePath={menuItem.routePath}
                    handleClickItem={handleClickItem}
                  />
                );
              })}
            </List>
          </>
        )}
      </ListContainer>
    </Drawer>
  );
};

export default SideMenu;
