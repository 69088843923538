/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import dayjs from 'dayjs';
import * as types from 'src/store/types';
import update from 'immutability-helper';
import camelCase from 'lodash-es/camelCase';

import { IIdName, IAllWorkspacesOfAUser, IRole } from 'src/types';
import { camelizeKey } from 'src/utils/keyFormat';
import { removeWorkspaceToken } from 'src/storage/workspaceTokenStorage';
import { removeWorkspace } from 'src/storage/workspaceStorage';
import { getAuthToken, removeAuthToken } from 'src/storage/tokenStorage';

const timezoneOffset = dayjs().utcOffset();

const initialState = {
  token: getAuthToken(),
  profile: {},
  users: [] as any,
  churches: [] as IIdName | [],
  roles: [] as IIdName | [],
  workspaces: [] as IAllWorkspacesOfAUser[],
  clientIP: '',
};

const reducer = (state = initialState, action: any): typeof initialState => {
  const { type, payload } = action;

  switch (type) {
    case types.INIT_USER_STATE: {
      return {
        ...state,
        users: [],
      };
    }
    case types.USER_LOGOUT_DONE: {
      removeAuthToken();
      removeWorkspaceToken();
      removeWorkspace();
      return {
        ...initialState,
        token: '',
      };
    }
    case types.USER_LOGIN_CALL: {
      return state;
    }
    case types.USER_LOGIN_DONE: {
      return {
        ...state,
        token: payload.response.data.access_token,
      };
    }
    case types.USER_LOGIN_FAIL: {
      return state;
    }
    case types.GET_ALL_WORKSPACE_OF_A_USER_DONE: {
      return {
        ...state,
        workspaces: payload.response.data.workspaces,
      };
    }
    case types.GET_USERS_DONE: {
      const users = payload.response.data
        .map((user: any) => camelizeKey(user))
        .map((user: any) => ({
          ...user,
          lastLoginAt: dayjs(user.lastLoginAt).add(timezoneOffset, 'minute'),
          createdAt: dayjs(user.createdAt).add(timezoneOffset, 'minute'),
          updatedAt: dayjs(user.updatedAt).add(timezoneOffset, 'minute'),
        }));
      return update(state, {
        users: { $set: users },
      });
    }
    case types.GET_USER_FIELDS_OPTIONS_DONE: {
      const {
        response: { data },
      } = payload;
      const fieldsOptions = {} as any;
      for (const key in data) {
        fieldsOptions[camelCase(key)] = data[key].map(camelizeKey);
      }
      const { churches, roles } = fieldsOptions;
      return update(state, {
        churches: { $set: churches },
        roles: { $set: roles },
      });
    }
    case types.GET_PROFILE_DONE: {
      const {
        response: { data },
      } = payload;
      const newData = camelizeKey({
        ...data,
        roles: data.roles.map((role: IRole) => camelizeKey(role)),
      });
      const roleNames = newData.roles.map((role: IRole) => role.name);
      return update(state, {
        profile: {
          $set: {
            ...newData,
            avatarUrl: newData.avatarUrl ? `${newData.avatarUrl}?a=${Math.random()}` : newData.avatarUrl,
            isNewcomer: roleNames.length === 1 && roleNames.includes('guest'),
            isRoot: roleNames.includes('super_administrator'),
          },
        },
      });
    }
    case types.SET_CLIENT_IP: {
      return update(state, {
        clientIP: { $set: payload.clientIP },
      });
    }
    default: {
      return state;
    }
  }
};

export default reducer;
