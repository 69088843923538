import * as React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import snakeCase from 'lodash-es/snakeCase';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { ButtonBase } from '@mui/material';

import * as actions from 'src/store/actions';
import { BOTTOM_NAVIGATION_BAR_HEIGHT } from 'src/theme';
import { GUIDE_MENU } from 'src/pages/Main/SideMenu/menuLists';
import { useAccessControl } from 'src/hooks/useAccessControl';
import { useLog } from 'src/hooks/useLog';
import { useBottomNav } from 'src/hooks/useBottomNav';

const TRANSLATION_PATH = 'pages';
const ACTIVE_COLOR = '#1976d2';

const TabsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`;

const Container = styled.div<{
  $height: number;
}>`
  width: 100%;
  height: ${props => props.$height}px;
  background: #fff;
  box-shadow: 1px 2px 8px #00000029;
  position: relative;
  z-index: 100;
`;

const activeTabStyle = css`
  color: ${ACTIVE_COLOR};
  svg {
    fill: ${ACTIVE_COLOR};
  }
`;

const TabButton = styled(ButtonBase)<{
  $isActive: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px 6px;
  width: 100%;
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  svg {
    fill: rgba(0, 0, 0, 0.54);
  }
  ${props => props.$isActive && activeTabStyle}
`;

const BottomMenu = (): JSX.Element => {
  const { log } = useLog();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { showBottomNav } = useBottomNav();
  const { hasAccessRight } = useAccessControl();
  const menus = GUIDE_MENU.filter(menuItem => !['home'].includes(menuItem.key)).filter(menuItem => hasAccessRight({ functionName: menuItem.key }));

  const handleClickItem = (routePath: string) => {
    navigate(routePath);
    dispatch(
      actions.globalPlayerSetOpenPlaylist({
        openPlaylist: false,
      }),
    );
  };

  return (
    <Container $height={showBottomNav ? BOTTOM_NAVIGATION_BAR_HEIGHT : 0}>
      <TabsContainer>
        {menus.map(menuItem => {
          const isActive = location.pathname === `/${menuItem.key}`;
          return (
            <TabButton
              key={menuItem.key}
              $isActive={isActive}
              onClick={() => {
                log({
                  triggerType: 'click_bottom_menu',
                  description: menuItem.key,
                });
                handleClickItem(menuItem.routePath);
              }}
            >
              <menuItem.icon />
              {t(`${TRANSLATION_PATH}:${snakeCase(menuItem.key)}`)}
            </TabButton>
          );
        })}
      </TabsContainer>
    </Container>
  );
};

export default BottomMenu;
